export const ROUTES = {
  DOCUMENTS: 'DOCUMENT_LIST'
};

export const documents_list = {
  name: ROUTES.DOCUMENTS,
  path: '/agent/onboarding/documents',
  component: () => import('@/pages/agent/onboarding/documents/list.vue')
};

export default [documents_list];
