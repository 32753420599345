import check from 'check-types';
import merge from 'lodash/merge';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import * as SentryTypes from '@sentry/types';
import {
  ACTION_LABEL_AND_LINK,
  CAP_TO_ROUND_MONTHLY_RENT,
  CLAIMS_CASE_STATUSES,
  COLLECTION_STATUS_OPTIONS,
  DOCUMENT_TYPES,
  FLATBOND_STATUS_OBJECTS,
  FLATBOND_TYPES,
  LANDLORD_OFFER_STATUSES,
  MINIMUM_PASSWORD_LENGTH,
  NON_EXISTING_CLAIMS_CASE_STATUS,
  NON_EXISTING_DOCUMENT_TYPE,
  NON_EXISTING_FLATBOND_STATUS,
  NON_EXISTING_FLATBOND_TYPE,
  NON_EXISTING_LANDLORD_OFFER_STATUS,
  NOT_AVAILABLE,
  NOTIFICATION_TYPES,
  REFERENCE_SUGGESTED_DECISION_OBJECTS,
  RENT_PERIODS,
  TRANSFER_FUNDS_STATUS_OPTIONS,
  UK_DEPOSIT_CAP_BREAK_POINT,
  UK_DEPOSIT_CAP_HIGH_WEEKS_LIMIT,
  UK_DEPOSIT_CAP_LOW_WEEKS_LIMIT,
  WRITE_OFF_REASONS
} from '@/libs/utils/constants';
import config from '@/libs/utils/config';
import countryCodes from '@/libs/dropdown_options/country_codes';
import { computed, ref, unref } from '@vue/composition-api';
import PhoneNumber from 'awesome-phonenumber';
import { REFERENCING_REQUEST_STATUSES, USER_TYPES } from '@/libs/constants/generatedConstants';
import { UNACCEPTABLE_FILE_EXTENSIONS } from '@/libs/constants/default';

const CLAIM_CATEGORIES = resolveConstant('CLAIM_CATEGORIES');
const CLAIM_STATUSES = resolveConstant('CLAIM_STATUSES');
const NON_EXISTING_STATUS = resolveConstant('NON_EXISTING_STATUS');
const NON_EXISTING_CATEGORY = resolveConstant('NON_EXISTING_CATEGORY');

const HTTP_SERVER_ERROR_MIN_CODE = 500;

export function extractCurrentTenantFromPlan(config, plan) {
  if (config.viewingAs !== USER_TYPES.TENANT) return;
  const tenants =
    plan && plan.tenants.filter(tenant => tenant.user && tenant.user.id === config.user.id);
  return tenants && tenants.length === 1 ? tenants[0] : null;
}

export function extractLeadTenantFromPlan(plan) {
  return plan && plan.tenants.find(tenant => tenant.isLeadTenant);
}

export function calculateMaxDepositFromWeeklyRentInCents(rentInCents) {
  if (!rentInCents) return 0;
  const rentInUnits = currencyCentsToUnits(rentInCents);
  let maxDepositMultiplier = UK_DEPOSIT_CAP_LOW_WEEKS_LIMIT;
  if (rentInUnits * 52 >= UK_DEPOSIT_CAP_BREAK_POINT) {
    maxDepositMultiplier = UK_DEPOSIT_CAP_HIGH_WEEKS_LIMIT;
  }
  return rentInCents * maxDepositMultiplier;
}

export function calculateRentByWeekInPence(rentPeriod, rentAmount) {
  if (rentPeriod === RENT_PERIODS.MONTH) {
    // We implemented a calculation that rounded down to get the weekly rent but
    // needed to revert it to fix rent share.  See Shortcut story 12522 for more details.
    // let weeklyRentAmountInPence = Math.floor(((rentAmount * 12) / 52) * 100);
    let weeklyRentAmountInPence = Math.round(((rentAmount * 12) / 52) * 100);
    return weeklyRentAmountInPence;
  }

  if (rentAmount) {
    return rentAmount * 100;
  }

  return 0;
}

export function vatPercentageToDecimal(vatPercentage) {
  return vatPercentage / 100;
}

export function getActionLabelAndLink(actionType) {
  if (!actionType) {
    return {};
  } else {
    return getFromDictionary(ACTION_LABEL_AND_LINK, actionType, 'Unable to find action type');
  }
}

export function getFromDictionary(dict, key, error) {
  const toReturn = key && dict[key];
  if (toReturn) {
    return toReturn;
  }
  console.error(error);
  return '';
}

export function checkForUnacceptableFileExtension(file) {
  return UNACCEPTABLE_FILE_EXTENSIONS.some(extension => file.name.endsWith(extension));
}

export function settingsRoute() {
  switch (config.viewingAs) {
    case 'admin':
      return '/ops/settings/';
    case 'agent':
      return '/agent/settings/';
    case 'landlord':
      return '/landlord/settings/';
    case 'guarantor':
      return '/guarantor/settings/';
    case 'tenant':
      return '/tenant/settings/';
  }
}

export function isGoodPassword(password) {
  return password != null && password.length >= MINIMUM_PASSWORD_LENGTH;
}

export function isPhoneFormat(phone) {
  const code = PhoneNumber(phone).getRegionCode();
  const pn = PhoneNumber(phone, code);
  return pn.isValid() || phone === '';
}

export function generateRentPeriodDropdownOptions() {
  return Object.entries(RENT_PERIODS).map(period => {
    return {
      value: period[1],
      label: `Per ${period[1]}`
    };
  });
}

export function calculateAffordability(salaryInPence, multiplier) {
  return Math.round((salaryInPence * 100) / 12 / multiplier);
}

export function formatCurrencyFromCentsIncludingVat(costInCents) {
  const costInPounds = formatCurrencyFromCents(costInCents);
  return `${costInPounds} (incl VAT)`;
}

export function formatCurrencyFromCents(costInCents) {
  const costInCurrencyUnits = currencyCentsToUnits(costInCents);
  return `£${parseFloat(costInCurrencyUnits).toFixed(2)}`;
}

export function currencyCentsToUnits(costInCents) {
  if (costInCents) return costInCents / 100;
  return costInCents;
}

export function addVat(costInCents) {
  return costInCents * 1.2;
}

export function currencyUnitsToCents(costInCurrencyUnits) {
  if (costInCurrencyUnits) {
    const cents = Number(costInCurrencyUnits);
    return Math.round(cents * 100);
  }
  return costInCurrencyUnits;
}

export function resolveCategoryValueToHelpText(value) {
  const category = CLAIM_CATEGORIES.find(flatbondClaimCategory => {
    return flatbondClaimCategory.value === value;
  });

  if (category) {
    return category.helpText;
  }

  logMessageOnSentry(`No help text found for claim category with value: ${value}`);

  return NON_EXISTING_CATEGORY;
}

export function resolveCategoryValueToLabel(value) {
  const category = CLAIM_CATEGORIES.find(flatbondClaimCategory => {
    return flatbondClaimCategory.value === value;
  });

  if (category) {
    return category.label;
  }

  logMessageOnSentry(`No label found for claim category with value: ${value}`);

  return NON_EXISTING_CATEGORY;
}

export function resolveChargeStatusValueToLabel(value) {
  const claim = CLAIM_STATUSES.find(flatbondClaimStatus => {
    return flatbondClaimStatus.value === value;
  });

  if (claim) {
    return claim.label;
  }

  logMessageOnSentry(`No label found for claim status with value: ${value}`);

  return NON_EXISTING_STATUS;
}

export function resolveCollectionsStatusValueToLabel(value) {
  const caseInDebtCollection = COLLECTION_STATUS_OPTIONS.find(collectionStatus => {
    return collectionStatus.value === value;
  });

  if (caseInDebtCollection) {
    return caseInDebtCollection.label;
  }

  logMessageOnSentry(`No label found for collection status with value: ${value}`);

  return NON_EXISTING_STATUS;
}

export function resolveChargeStatusValueToLabelClass(value) {
  const claim = CLAIM_STATUSES.find(flatbondClaimStatus => {
    return flatbondClaimStatus.value === value;
  });

  if (claim) {
    return claim.labelClass;
  }

  logMessageOnSentry(`No label class found for claim status with value: ${value}`);

  return NON_EXISTING_STATUS;
}

export function resolveFlatbondTypeToLabel(value) {
  const type = FLATBOND_TYPES.find(flatbondType => {
    return flatbondType.value === value;
  });

  if (type) {
    return type.label;
  }

  logMessageOnSentry(`No label found for flatbond type: ${type}`);

  return NON_EXISTING_FLATBOND_TYPE;
}

export function resolveFlatbondTypeToColourClass(value) {
  const type = FLATBOND_TYPES.find(flatbondType => {
    return flatbondType.value === value;
  });

  if (type) {
    return type.colourClass;
  }

  logMessageOnSentry(`No colour class found for flatbond type: ${type}`);

  return NON_EXISTING_FLATBOND_TYPE;
}

export function resolveFlatbondStatusToLabel(value) {
  const flatbondStatus = FLATBOND_STATUS_OBJECTS.find(flatbondStatus => {
    return flatbondStatus.value === value;
  });

  if (flatbondStatus) {
    return flatbondStatus.label;
  }

  logMessageOnSentry(`No label found for flatbond status: ${flatbondStatus}`);

  return NON_EXISTING_FLATBOND_STATUS;
}

export function resolveFlatbondStatusToClass(value) {
  const flatbondStatus = FLATBOND_STATUS_OBJECTS.find(flatbondStatus => {
    return flatbondStatus.value === value;
  });

  if (flatbondStatus) {
    return flatbondStatus.colourClass;
  }
  logMessageOnSentry(`No class found for flatbond status: ${flatbondStatus}`);

  return NON_EXISTING_FLATBOND_STATUS;
}

export function resolveSuggestedDecisionToObject(reference) {
  if (reference.status === REFERENCING_REQUEST_STATUSES.ON_HOLD) {
    return REFERENCE_SUGGESTED_DECISION_OBJECTS.on_hold;
  }
  if (!reference.response || !reference.response.recommendation_status) {
    return REFERENCE_SUGGESTED_DECISION_OBJECTS.pending;
  }
  const style = REFERENCE_SUGGESTED_DECISION_OBJECTS[reference.response.recommendation_status];

  return {
    ...style,
    label: reference.response.recommendation,
    body: reference.response.recommendation_description
  };
}

export function resolveCaseStatusToLabel(value) {
  const claimsCaseStatus = CLAIMS_CASE_STATUSES.find(claimsCaseStatus => {
    return claimsCaseStatus.value === value;
  });

  if (claimsCaseStatus) {
    return claimsCaseStatus.label;
  }

  logMessageOnSentry(`No label found for claims case status: ${claimsCaseStatus}`);

  return NON_EXISTING_CLAIMS_CASE_STATUS;
}

export function resolveCaseStatusToClass(value) {
  const claimsCaseStatus = CLAIMS_CASE_STATUSES.find(claimsCaseStatus => {
    return claimsCaseStatus.value === value;
  });

  if (claimsCaseStatus) {
    return claimsCaseStatus.colourClass;
  }

  logMessageOnSentry(`No class found for claims case status: ${claimsCaseStatus}`);

  return NON_EXISTING_CLAIMS_CASE_STATUS;
}

export function resolveDocumentTypeValueToLabel(value) {
  const type = DOCUMENT_TYPES.find(documentType => {
    return documentType.value === value;
  });

  if (type) {
    return type.label;
  }

  logMessageOnSentry(`No label found for document type with value: ${value}`);

  return NON_EXISTING_DOCUMENT_TYPE;
}

export function resolveLandlordOfferStatusToLabel(value) {
  const flatbondStatus = LANDLORD_OFFER_STATUSES.find(flatbondStatus => {
    return flatbondStatus.value === value;
  });

  if (flatbondStatus) {
    return flatbondStatus.label;
  }

  logMessageOnSentry(`No label found for flatbond status: ${flatbondStatus}`);

  return NON_EXISTING_LANDLORD_OFFER_STATUS;
}

export function resolveLandlordOfferStatusToClass(value) {
  const claimsCaseStatus = LANDLORD_OFFER_STATUSES.find(claimsCaseStatus => {
    return claimsCaseStatus.value === value;
  });

  if (claimsCaseStatus) {
    return claimsCaseStatus.colourClass;
  }

  logMessageOnSentry(`No class found for claims case status: ${claimsCaseStatus}`);

  return NON_EXISTING_LANDLORD_OFFER_STATUS;
}

export function copyToClipboardAndConfirm(vueInstance, text) {
  try {
    copyTextToClipboard(text);
    showNotification(vueInstance, 'Copied to clipboard');
  } catch {
    showNotification(vueInstance, 'Can not copy to clipboard', 'error');
  }
}

/**
 * Copies passed text to clipboard.
 * Text is copied from a temporary element using execCommand function.
 * If any element had been selected before function was triggered it will be selected again.
 */
export function copyTextToClipboard(text) {
  const element = document.createElement('textarea');
  element.value = text;
  element.setAttribute('readonly', '');
  element.classList.add('clipboard-buffor-element');
  document.body.appendChild(element);
  const selected =
    document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
  element.select();
  document.execCommand('copy');
  document.body.removeChild(element);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
}

export function showNotification(
  vueInstance,
  text,
  type = NOTIFICATION_TYPES.SUCCESS,
  group = 'main',
  title = null,
  preventNotifications = false
) {
  const options = {
    title: text,
    type: type,
    group: group,
    position: 'top-right'
  };

  if (title) {
    options['text'] = options.title;
    options.title = title;
  }

  if (preventNotifications === false) {
    vueInstance.$notify(options);
  }
}

export function calculateRentByMonthFromFloat(rentPeriod, rentForPeriod) {
  if (rentPeriod === RENT_PERIODS.WEEK) {
    return Math.round(((rentForPeriod * 52) / 12) * 100);
  }
  if (rentForPeriod) {
    return rentForPeriod * 100;
  }
  return 0;
}

export function convertWeeklyToMonthlyRent(rentInCents) {
  return Math.ceil(52 * rentInCents) / 12;
}

export function convertAndRoundWeeklyToMonthlyRent(rentInCents) {
  const convertedWeeklyToMonthlyRentInCents = convertWeeklyToMonthlyRent(rentInCents);
  const rentInPounds = convertedWeeklyToMonthlyRentInCents / 100;
  const rentRoundedToFullPound = Math.round(rentInPounds);
  const difference = rentInPounds - rentRoundedToFullPound;
  if (Math.abs(difference) > CAP_TO_ROUND_MONTHLY_RENT) {
    return Math.round(convertedWeeklyToMonthlyRentInCents);
  }
  return rentRoundedToFullPound * 100;
}

export function convertMonthlyToYearlyIncome(incomeInCents) {
  return incomeInCents * 12;
}

export function calculateRentByWeek(rentPeriod, rentForPeriod) {
  if (rentPeriod === RENT_PERIODS.MONTH) {
    return convertMonthlyRentToWeekly(rentForPeriod);
  }
  return rentForPeriod;
}

export function convertMonthlyRentToWeekly(rentInCents) {
  return Math.floor((rentInCents * 12) / 52);
}

export function convertWeeklyToMonthlyRentInUnits(rentInCents) {
  return convertWeeklyToMonthlyRent(rentInCents) / 100;
}

export function getInitialValue(initialValues, name, defaultValue) {
  if (initialValues && name in initialValues) {
    return initialValues[name];
  }
  return defaultValue;
}

function _addTenantIfExists(initialValues, fieldName, tenants) {
  if (initialValues && fieldName in initialValues) {
    tenants.push(initialValues[fieldName]);
  }
}

export function getInitialTenants(initialValues) {
  let tenants = [];
  _addTenantIfExists(initialValues, 'tenant1', tenants);
  _addTenantIfExists(initialValues, 'tenant2', tenants);
  _addTenantIfExists(initialValues, 'tenant3', tenants);
  _addTenantIfExists(initialValues, 'tenant4', tenants);

  return tenants;
}

export function findOptionWithValue(options, value, defaultOption) {
  const foundOption = options.find(option => option.value === value);

  return foundOption ? foundOption : defaultOption;
}

export function logMessageOnSentry(message, level = SentryTypes.Severity.Error, extras = {}) {
  Sentry.captureMessage(message, { extra: extras, level });
}

export function logExceptionOnSentry(error, extras = {}, level = SentryTypes.Severity.Error) {
  Sentry.captureException(error, { extra: extras, level });
}

export function logAPIError(errorName, error) {
  error.name = errorName;

  const extraErrorDetails = {};
  if (error.response) {
    extraErrorDetails.data = error.response.data;
    extraErrorDetails.status = error.response.status;
    extraErrorDetails.headers = error.response.headers;
  } else if (error.request) {
    extraErrorDetails.request = error.request;
  } else {
    extraErrorDetails.message = error.message;
  }
  extraErrorDetails.config = error.request;

  const errorLevel =
    extraErrorDetails.status && extraErrorDetails.status >= HTTP_SERVER_ERROR_MIN_CODE
      ? SentryTypes.Severity.Error
      : SentryTypes.Severity.Info;

  logExceptionOnSentry(error, extraErrorDetails, errorLevel);

  return extraErrorDetails;
}

export function getStaticUrlFromPlaceholder(path, staticPlaceholder) {
  const placeholderWithVersionRegExp = new RegExp('placeholder.*');
  const regExpMatch = placeholderWithVersionRegExp.exec(staticPlaceholder);
  return `${staticPlaceholder.replace(regExpMatch, `${path}`)}`;
}

export function resolveConstant(constantName) {
  let constants = require('../constants/default');

  const projectConstants = require(`../constants/flatpie`);

  merge(constants, projectConstants);
  const constant = constants[constantName];

  if (typeof constant === 'undefined') {
    logMessageOnSentry(`Constant not found: ${constantName}`);
    throw new Error(`Constant not found: ${constantName}`);
  }

  return constant;
}

export function resolveCompaniesHouseUrl(companynumber) {
  return `https://beta.companieshouse.gov.uk/company/${companynumber}`;
}

export function setPageTitle(title, subtitle) {
  const titleElement = window.document.getElementById('page-title');
  const subtitleElement = window.document.getElementById('page-sub-title');

  if (titleElement) {
    titleElement.textContent = title;
  }
  if (subtitleElement) {
    subtitleElement.textContent = subtitle;
  }
}

export function buildQuery(query) {
  let firstElement = true;
  return Object.keys(query).reduce((queryString, parameter) => {
    const separator = firstElement ? '?' : '&';
    const value = query[parameter];
    const shouldSkip = value === null || value === undefined || value === '';

    if (!shouldSkip && firstElement) {
      firstElement = false;
    }

    return shouldSkip ? queryString : `${queryString}${separator}${parameter}=${value}`;
  }, '');
}

export function capitalise(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function titleCase(string) {
  if (string !== '') {
    const split = string.split('');
    return (
      capitalise(split[0]) +
      split
        .slice(1)
        .map(letter => letter.toLowerCase())
        .reduce((lowerString, letter) => lowerString + letter, '')
    );
  }
  return '';
}

export function snakeCaseToSentence(string) {
  return capitalise(string.replace(/_/g, ' '));
}

export function createOptionsFromConstant(
  constant,
  formatFunction = value => snakeCaseToSentence(value),
  filterFunction = value => value === value
) {
  const values = Object.values(constant).filter(filterFunction);
  const optionsArray = [];
  values.forEach(value => {
    optionsArray.push({ value: value, label: formatFunction(value) });
  });

  return optionsArray;
}

export function createOptionsFromLocaleIdMapping(
  localeIdMapping,
  $t,
  filterFunction = value => value === value
) {
  const values = Object.keys(localeIdMapping).filter(filterFunction);
  const optionsArray = [];
  values.forEach(value => {
    optionsArray.push({ value: value, label: $t(localeIdMapping[value]) });
  });

  return optionsArray;
}

export function getErrorMessageForFileExtension() {
  const extensions = UNACCEPTABLE_FILE_EXTENSIONS.join(', ');
  return `We do not accept files of the format: ${extensions}`;
}

export function formatUtcDateForDisplay(date) {
  return moment.utc(date).format('Do MMM, YYYY');
}

export function formatUtcDateForDisplayWithTime(date) {
  return moment.utc(date).format('Do MMM, YYYY, hh:mm A');
}

export function formatUtcDateForDisplayRelative(date) {
  return moment.utc(date).fromNow();
}

export function generateNamedOptions(namedEntities) {
  return namedEntities.map(entity => {
    return {
      label: entity.name,
      value: entity.id
    };
  });
}

export function convertNumberToOrdinal(number) {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const v = number % 100;
  return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
}

export function formatDateForApi(date) {
  return date ? moment(date).format('YYYY-MM-DD') : null;
}

export function replaceUnderscores(string, replaceWith = ' ') {
  return string.replace(new RegExp('_', 'g'), replaceWith);
}

export function resolveBankTransfersStatusToLabel(value) {
  const status = TRANSFER_FUNDS_STATUS_OPTIONS.find(transferStatus => {
    return transferStatus.value === value;
  });

  if (status) {
    return status.label;
  }

  logMessageOnSentry(`No label found for bank transfer status: ${status}`);

  return NOT_AVAILABLE;
}

export function resolveBankTransfersStatusToClass(value) {
  const status = TRANSFER_FUNDS_STATUS_OPTIONS.find(transferStatus => {
    return transferStatus.value === value;
  });

  if (status) {
    return status.colourClass;
  }

  logMessageOnSentry(`No class found for bank transfer status: ${status}`);

  return NOT_AVAILABLE;
}

export function getFullAddress(address, postcode, city) {
  return `${address || ''} ${postcode || ''} ${city || ''}`.trim();
}

// OPTIONS FOR SELECT
export function getTitleOptions() {
  const DEPOSIT_PERSON_TITLES = resolveConstant('DEPOSIT_PERSON_TITLES');
  return Object.values(DEPOSIT_PERSON_TITLES).map(value => {
    return {
      value,
      label: value
    };
  });
}

/**
 * Calculate end date based on start date and duration (in months)
 * @param {Object} startDate: moment object
 * @param {String} durationInMonths: string
 */
export function calculateEndDate(startDate, durationInMonths) {
  if (startDate && durationInMonths) {
    return moment(startDate).add(durationInMonths, 'months');
  }
  return undefined;
}

export function getLastInteraction(chargeCase) {
  const allInteractions = chargeCase.flatbond.claims.reduce((list, claim) => {
    return list.concat(claim.claim_counters);
  }, []);
  allInteractions.sort((claimCouterA, claimCouterB) => {
    const dateA = new Date(claimCouterA.created_on);
    const dateB = new Date(claimCouterB.created_on);
    if (dateA < dateB) {
      return 1;
    } else if (dateA > dateB) {
      return -1;
    }
    return 0;
  });
  return allInteractions[0];
}

export function lookupNationality(code) {
  if (!code) return undefined;
  return countryCodes.find(country => country.value === code);
}

export function reviewRequestSent(selectedAdministratorId, billingDetails) {
  if (
    selectedAdministratorId &&
    billingDetails.administrator &&
    billingDetails.sent_for_review_on
  ) {
    return !!(
      billingDetails.sent_for_review_on &&
      selectedAdministratorId === billingDetails.administrator.id
    );
  }

  return false;
}

export const scrollToTop = element => {
  try {
    element.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  } catch (e) {
    try {
      element.scrollTo(0, 0);
    } catch (e) {
      element.scrollTop = 0;
    }
  }
};

export const generateOptionLabels = (options, $t) => {
  check.assert.array(options, 'the options argument must be an array');
  check.assert.function($t, 'the $t argument must be a function');
  return options.map(({ label, value }) => ({ label: $t(label), value: value }));
};

export const useCurrencyValue = prop => {
  unref(prop); // normalises - primitive or ref
  const currency = ref(prop);

  return computed({
    get() {
      return currency.value || currency.value === 0 ? currencyCentsToUnits(currency.value) : null;
    },
    set(val) {
      currency.value = currencyUnitsToCents(val);
    }
  });
};

export const paymentReference = flatbondId => {
  return `f${flatbondId}`;
};

export function resolveWriteOffReasonValueToLabel(value) {
  const reason = WRITE_OFF_REASONS.find(reasonObject => {
    return reasonObject.value === value;
  });

  if (reason) {
    return reason.label;
  }

  logMessageOnSentry(`No label found for reason with value: ${value}`);

  return 'Unknown Reason';
}
