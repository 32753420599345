import { ApiBase } from '@/libs/apis/base';
import {
  InternalPaymentPayOutModel,
  InternalPayoutSummaryModel,
  TruelayerManualTransactionModel,
  PaymentModel
} from '@/models/payment';
import querystring from 'querystring';

export class InternalPaymentPayOutApi extends ApiBase {
  constructor(vue) {
    super(vue, 'payment/internal/payout', true);
  }

  async list({ q, type, status, flatbond, pageSize, page } = {}) {
    const qs = querystring.stringify({
      type,
      status,
      flatbond,
      page_size: pageSize,
      page,
      search: q
    });
    const { data } = await this.get(`/?${qs}`);
    return {
      ...data,
      results: data.results.map(data => new InternalPaymentPayOutModel(data))
    };
  }

  async detail(id) {
    const { data } = await this.get(`/${encodeURI(id)}/`);
    return new InternalPaymentPayOutModel(data);
  }

  async update(id, { nameOnAccount, reference, accountNumber, sortCode, payTo, paidDate }) {
    const { data } = await this.put(`/${encodeURI(id)}/`, {
      name_on_account: nameOnAccount,
      reference,
      account_number: accountNumber,
      sort_code: sortCode,
      pay_to: payTo,
      paid_date: paidDate
    });
    return new InternalPaymentPayOutModel(data);
  }

  async approve(id) {
    const { data } = await this.post(`/${encodeURI(id)}/approve/`);
    return new InternalPaymentPayOutModel(data);
  }

  async markAsPaid(id) {
    const { data } = await this.post(`/${encodeURI(id)}/mark-as-paid/`);
    return new InternalPaymentPayOutModel(data);
  }

  async payoutSummaryForFlatbond(id) {
    const { data } = await this.get(`/${encodeURI(id)}/payout-summary-for-flatbond/`);
    return new InternalPayoutSummaryModel(data);
  }
}

export class ManualTransactionsApi extends ApiBase {
  constructor(vue) {
    super(vue, 'payment/internal/truelayer', true);
  }

  async list() {
    const { data } = await this.get(`manual-transactions`);
    return {
      ...data,
      results: data.map(data => new TruelayerManualTransactionModel(data))
    };
  }

  async listUnmatched() {
    const { data } = await this.get(`manual-transactions-not-matched`);
    return {
      ...data,
      results: data.map(data => new TruelayerManualTransactionModel(data))
    };
  }
}

export class InternalPaymentApi extends ApiBase {
  constructor(vue) {
    super(vue, 'payment/internal', true);
  }

  async listInProgressPaymentsOfTypeTraditionalDepositManual() {
    const { data } = await this.get(`traditional-deposit-payments-in-progress-list/`);
    return {
      ...data,
      results: data.map(data => new PaymentModel(data))
    };
  }

  async update({ id, reference }) {
    try {
      const { data } = await this.put('update-payment-reference/', { payment_id: id, reference });
      return data;
    } catch (error) {
      return { error };
    }
  }
}
