import { TrainingApiBase } from '@/libs/apis/training/base';

export class QuizApi extends TrainingApiBase {
  getOrCreate() {
    return super.get('quiz/get-or-create/', 'Failed to get or create quiz');
  }

  updateQuestion(data) {
    return super.put(`quiz/question/${data.id}/`, data, 'Failed to update question');
  }
}
