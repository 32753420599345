import contractRoutes from '@/pages/agent/onboarding/contract/';
import branchRoutes from '@/pages/agent/onboarding/branch/';
import documentRoutes from '@/pages/agent/onboarding/documents/';

export const ROUTES = {
  PARTNER_ONBOARDING: 'PARTNER_ONBOARDING'
};

export const onboarding = {
  name: ROUTES.PARTNER_ONBOARDING,
  path: '/agent/onboarding',
  component: () => import('@/pages/agent/onboarding/onboarding_flow.vue')
};

export default [...contractRoutes, ...branchRoutes, ...documentRoutes, onboarding];
