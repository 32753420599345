import BaseModel from '@/models/base';
import { formatCurrencyFromCents } from '@/libs/utils/helpers';
import TenantReferenceEmployerModel from '@/models/tenant/tenant_reference_employer';

export default class TenantReferenceModel extends BaseModel {
  constructor(data) {
    super(data);

    this.employer = this.employer
      ? new TenantReferenceEmployerModel(this.employer)
      : new TenantReferenceEmployerModel();
    this.affordabilityMultiplier = !this.affordabilityMultiplier
      ? 30
      : this.affordabilityMultiplier;
    this.incomeSource = this.incomeSource ? this.incomeSource : [];
    this.ccj = this.ccj ? this.ccj : false;
  }

  get formattedRent() {
    return this.rent ? formatCurrencyFromCents(this.rent) : formatCurrencyFromCents(0);
  }

  get formattedIncome() {
    return this.income ? formatCurrencyFromCents(this.income) : formatCurrencyFromCents(0);
  }

  get formattedAffordability() {
    return this.affordability
      ? formatCurrencyFromCents(this.affordability)
      : formatCurrencyFromCents(0);
  }

  defaults() {
    return {
      flatbondTenantId: null,
      guarantorId: null,
      rent: null,
      income: null,
      affordability: null,
      incomeAffordability: null,
      incomeSource: [],
      ccj: false,
      referencingResult: null,
      fullName: null,
      affordabilityMultiplier: 30
    };
  }
}
