import config from '@/libs/utils/config';

const REDIRECT_TO_HOME_IF_NOT_ADMIN = async (to, from, next) => {
  if (!config.isOnboarding || !config.isOrganizationAdmin) {
    window.location = '/';
    return;
  }

  next();
};

export const ROUTES = {
  HOME: 'HOME',
  WELCOME: 'CONTRACT_WELCOME',
  VIDEO: 'CONTRACT_VIDEO',
  CONTRACT: 'CONTRACT_SIGN',
  COMPLETE: 'CONTRACT_COMPLETE'
};

export const welcome = {
  name: ROUTES.WELCOME,
  path: '/agent/contract/welcome',
  beforeEnter: REDIRECT_TO_HOME_IF_NOT_ADMIN,
  component: () => import('@/pages/agent/onboarding/contract/welcome.vue'),
  meta: {
    NEXT_STEP: ROUTES.VIDEO
  }
};

export const contract = {
  name: ROUTES.CONTRACT,
  path: '/agent/contract/sign',
  beforeEnter: REDIRECT_TO_HOME_IF_NOT_ADMIN,
  component: () => import('@/pages/agent/onboarding/contract/contract.vue'),
  meta: {
    NEXT_STEP: ROUTES.COMPLETE
  }
};

export default [welcome, contract];
