import config from '@/libs/utils/config';

const REDIRECT_TO_HOME_IF_NOT_ADMIN = async (to, from, next) => {
  if (!config.isOnboarding || !config.isOrganizationAdmin) {
    window.location = '/';
    return;
  }

  next();
};

export const ROUTES = {
  ADD_BRANCH: 'ADD_BRANCH',
  BRANCH_LIST: 'BRANCH_LIST'
};

export const add_branch = {
  name: ROUTES.ADD_BRANCH,
  path: '/agent/branch/add',
  beforeEnter: REDIRECT_TO_HOME_IF_NOT_ADMIN,
  component: () => import('@/pages/agent/onboarding/branch/add.vue')
};

export const branch_list = {
  name: ROUTES.BRANCH_LIST,
  path: '/agent/branch/list',
  beforeEnter: REDIRECT_TO_HOME_IF_NOT_ADMIN,
  component: () => import('@/pages/agent/onboarding/branch/list.vue')
};

export default [add_branch, branch_list];
