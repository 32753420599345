import { InternalFlatbondApiBase } from './base';
import { InternalFlatbondModel } from '@/models/internal/flatbond';
import { InternalFlatbondDumpStatusModel } from '@/models/flatbond/dump';

export class InternalFlatbondApi extends InternalFlatbondApiBase {
  transferLandlordSignatureToAgent(flatbondId) {
    return super.post(
      `${flatbondId}/transfer-landlord-signature-to-agent/`,
      {},
      'Failed to transfer landlord signature to agent'
    );
  }

  skipLandlordSignature(flatbondId) {
    return super.post(
      `${flatbondId}/skip-landlord-signature/`,
      {},
      'Failed to skip landlord signature'
    );
  }

  updateFlatbondLandlord(flatbondId, landlordId) {
    return super.put(
      `${flatbondId}/update-landlord/`,
      { landlord_id: landlordId },
      'Failed to update landlord'
    );
  }

  createLandlordAndAddToFlatbond(flatbondId, landlord) {
    return super.post(
      `${flatbondId}/create-`,
      { landlord },
      'Failed to create landlord and add to flatbond'
    );
  }

  approveOnBehalfOfLandlord(flatbondId) {
    return super.post(
      `${flatbondId}/approve-on-landlord-behalf/`,
      {},
      'Failed to approve on landlords behalf'
    );
  }

  resetSignedDocuments(flatbondId) {
    return super.post(
      `${flatbondId}/reset-signed-documents/`,
      {},
      'Failed to reset signed documents'
    );
  }

  closeWithoutCharges(flatbondId) {
    return super.post(`${flatbondId}/close-without-claims/`, {}, 'Failed to close without charges');
  }

  updateFlatbondRentAmount(flatbondId, amount) {
    const data = {
      new_rent_per_week_in_cents: amount
    };

    return super.post(`${flatbondId}/update-rent/`, data, 'Failed to update rent amount');
  }

  removeTenant(flatbondId, tenantEmail) {
    const data = {
      tenant_email: tenantEmail
    };
    return super.post(`${flatbondId}/remove-tenant/`, data, 'Failed to remove tenant');
  }

  refreshMembershipFees(flatbondId) {
    return super.post(
      `${flatbondId}/refresh-membership-fee/`,
      {},
      'Failed to refresh membership fees'
    );
  }

  retrieveVerificationProgress(flatbondId) {
    return super.get(
      `${flatbondId}/verification`,
      'Could not retrieve verification progress for flatbond'
    );
  }

  updateVerificationProgress(productId, progress) {
    return super.put(
      `${productId}/verification/${progress.id}/`,
      progress,
      'Failed to update verification progress for flatbond'
    );
  }

  activateFlatbond(productId) {
    return super.post(`${productId}/verify/`, 'Failed to activate flatbond');
  }

  preVerifyFlatbond(productId) {
    return super.post(`${productId}/pre-verify/`, 'Failed to pre-verify flatbond');
  }

  setToTraditionalDeposit(productId) {
    return super.post(
      `${productId}/set-to-traditional-deposit/`,
      'Failed to set unselected plan to traditional deposit'
    );
  }

  get(flatbondId) {
    return super.get(`${flatbondId}`, 'Failed to get flatbond');
  }

  async detail(flatbondId) {
    const { data } = await super.get(`${flatbondId}/`, 'Failed to get flatbond');
    return new InternalFlatbondModel(data);
  }

  update(id, data) {
    return super.put(`${id}/`, data, 'Failed to update flatbond');
  }

  disableTrustpilotReviews(flatbondId) {
    return super.put(
      `${flatbondId}/disable-trustpilot-reviews/`,
      {},
      'Failed to disable the trustpilot reviews'
    );
  }

  reconcileFlatbondBankTransfer(flatbondId) {
    return super.put(
      `${flatbondId}/reconcile-flatbond-bank-transfer/`,
      {},
      'Failed to update payments'
    );
  }

  downloadSignedDocuments(flatbondId) {
    return super.post(
      `${flatbondId}/download-signed-documents/`,
      {},
      'Failed to download signed documents'
    );
  }

  addTenant(flatbondId, email, firstName = null, lastName = null, tenancyInScotland = null) {
    const data = {
      new_tenant_email: email,
      new_tenant_first_name: firstName,
      new_tenant_last_name: lastName,
      tenancy_in_scotland: tenancyInScotland
    };

    return super.post(`${flatbondId}/add-tenant/`, data, `Failed to add tenant to flatbond`);
  }

  async createCollectionDocumentsDump(planId) {
    const response = await this.post(`${planId}/collection-documents-dump/`);
    return new InternalFlatbondDumpStatusModel(response.data);
  }

  async fetchCollectionDocumentsDump(planId, dumpId) {
    const response = await this.get(`${planId}/collection-documents-dump/?id=${dumpId}`);
    return new InternalFlatbondDumpStatusModel(response.data);
  }

  migratePlanToNewGroupBranch(planId, groupId, branchId) {
    return super.put(
      `${planId}/migrate-plan-to-new-group-branch/`,
      {
        group_id: groupId,
        branch_id: branchId
      },
      'Failed to migrate plan to new group branch'
    );
  }

  changeProductType(planId, productType, depositAmount = null, leadTenantId = null) {
    return super.put(
      `${planId}/change-product-type/`,
      {
        product_type: productType,
        deposit_amount: depositAmount,
        lead_tenant_id: leadTenantId
      },
      'Failed to update product type'
    );
  }
}
